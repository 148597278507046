import { PanelHeader,
    TabsHeader,
    userDetailsProxy,
    UserDetails,
    TwoFactorSetting,
    TwoFactor
} from "@homefile/components";

import {
    DrawerBody,
    DrawerContent,
    DrawerHeader,
    Center,
    Modal,
    ModalOverlay,
    ModalContent,
} from '@chakra-ui/react'
import { t } from 'i18next'
import GenericDialog from "../../Dialogs/GenericDialog";
import { usePaymentInfo } from "../../../hooks/usePaymentInfo";
import {useState, useEffect} from 'react'
import BeatLoader from "react-spinners/BeatLoader";
import { Profile } from "../../../assets/images";
import AccountTab from "./AccountTab";
import AddSubscriptionModule from "../../Modules/AddSubscriptionModule";
import { useGetUserSubscriptionData } from "../../../hooks/useGetUserSubscriptionData";
import { useTwoFactor } from "../../../hooks/useTwoFactor";
import { RootState } from "../../../app/store";
import { useAppSelector } from "../../../app/hooks";

const ProfileDrawer = ({ handleClose, defaultTab }: { handleClose: () => void , defaultTab?: number}) => {
    const bg = useAppSelector((state: RootState) => state.auth.avatarBg)
    const {
        handleCloseModal,
        handleDrawerSubmit,
        userCard,
        isLoading,
        openModal,
        modalHeader,
        modalBody,
        cancelText,
        user,
        receipts,
        cardLoading,
        cardSuccess,
        handleCardSuccess
    } = usePaymentInfo({authenticated: true, authState: null})

    const {activeSubscription} = useGetUserSubscriptionData(true)

    const {
        defaultValue, 
        email,
        sms,
        onChange,
        onSave,
        active,
        loading,
        confirmTwoFactor,
        openTwoFactorModal, 
        updateOpenTwoFactorModal,
        twoFactorMethod
    } = useTwoFactor()

    const handleSubmit = () => {
        const { fields: userFields } = userDetailsProxy
        const form = {userFields}
        handleDrawerSubmit(form)
      }

    const profileTab = {
        label: t('myProfile.tabs.tab1'),
        component: <>
            <UserDetails {...user} bg={bg} onSave={handleSubmit} />
            <TwoFactorSetting 
                email={email}
                sms={sms}
                onChange={onChange}
                onSave={onSave}
                defaultValue={defaultValue === "" ? undefined : defaultValue}
                twoFactor={active}
                loading={loading}
            />
        </>,
      }

    const accountTab = {
        label: t('myProfile.tabs.tab2'),
        component: <AccountTab handleCardSuccess={handleCardSuccess} cardSuccess={cardSuccess} cardLoading={cardLoading} userCard={userCard} receipts={receipts} handleCardSubmit={() => {}} activePayment={activeSubscription} />,
    }

    const paymentTab = {
        label: t('myProfile.tabs.tab3'),
        component: <AddSubscriptionModule handleCardSuccess={handleCardSuccess} cardSuccess={cardSuccess} cardLoading={cardLoading} userCard={userCard} receipts={receipts} handleCardSubmit={() => {}} activePayment={true} />,
    }

    const noPaymentTabs = [profileTab, accountTab]

    const paymentTabs = [...noPaymentTabs, paymentTab]


    return (
        <>
            <DrawerContent bg="lightBlue.1" p="0">
                <DrawerHeader p="0">
                    <PanelHeader
                    handleCloseButton={handleClose}
                    icon={Profile}
                    title={t('myProfile.title')}
                    />
                </DrawerHeader>
                <DrawerBody p="0" mb={["0","104px"]}>
                    {
                    isLoading ? (
                        <Center h="4rem" bg="white">
                            <BeatLoader color="gray" size={8} />
                        </Center>
                    ) : (
                        <TabsHeader tabList={activeSubscription ? paymentTabs : noPaymentTabs} defaultIndex={defaultTab} />
                    )
                    }
                </DrawerBody>
            </DrawerContent>
            <GenericDialog header={modalHeader} body={modalBody} action={() => {}} close={handleCloseModal} openModal={openModal} actionText={""} cancelText={cancelText}  />
            <Modal isOpen={openTwoFactorModal} onClose={() => {updateOpenTwoFactorModal(false)}}>
                <ModalOverlay />
                <ModalContent>
                    <TwoFactor twoFactorMethod={twoFactorMethod} isLoading={false} handleReset={confirmTwoFactor} handleResend={() => {}} />
                </ModalContent>
            </Modal>
        </>
    )
}

export default ProfileDrawer;