import { homefileApi } from './emptySplitApi'

const userApi = homefileApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data: any) => ({
        url: "/api/login",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["User", "Homes"],
    }),
    createPin: builder.mutation({
      query: (data: any) => ({
        url: `/api/user/code/${data.email}`,
        method: "POST",
        body: {password: data.password}
      }),
      invalidatesTags: ["User", "Homes"],
    }),
    updatePassword: builder.mutation({
      query: (data: any) => ({
        url: "api/user/update-password",
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getTestAuth: builder.query({
      query: () => "/api/test-auth",
    }),
    getUser: builder.query({
      query: () => "/api/user/profile",
      providesTags: ["User"],
    }),
    getDefaultConfig: builder.query({
      query: () => "/api/default-config",
    }),
    getUserByEmail: builder.query({
      query: (data: any) => `/api/user/${data.email}`,
      providesTags: ["User"],
    }),
    getUserById: builder.query({
      query: (id: string) => `/api/user/id/${id}`,
      providesTags: ["User"],
    }),
    createUser: builder.mutation({
      query: (data: any) => ({
        url: "/api/user",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    updateUser: builder.mutation({
      query: (data: any) => ({
        url: "/api/user",
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["User"],
    }),
    userPasswordRecovery: builder.mutation({
      query: (email: string) => ({
        url: "/api/user/set-password-recover",
        method: "PATCH",
        body: { email },
        headers: {
          "Content-Type": "application/json",
        }
      }),
    }),
    userResetPassword: builder.mutation({
      query: (data: any) => ({
        url: "/api/user/reset-password",
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        }
      }),
    }),
    userSendFeedback: builder.mutation({
      query: (data: any) => ({
        url: "/api/feedback",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
    }),
    setTwoFactor: builder.mutation({
      query: (data: {provider: string | null, code: string}) => ({
        url: "api/user/set-2fa",
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    confirmTwoFactor: builder.mutation({
      query: (data: {provider: string | null, code: string}) => ({
        url: "api/user/confirm-2fa",
        method: "PATCH",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
      invalidatesTags: ["User"],
    }),
  })
})

export const {
  useGetUserQuery,
  useCreateUserMutation,
  useGetUserByEmailQuery,
  useLoginMutation,
  useUpdatePasswordMutation,
  useGetTestAuthQuery,
  useUserPasswordRecoveryMutation,
  useUserResetPasswordMutation,
  useGetUserByIdQuery,
  useCreatePinMutation,
  useUpdateUserMutation,
  useGetDefaultConfigQuery,
  useUserSendFeedbackMutation,
  useSetTwoFactorMutation,
  useConfirmTwoFactorMutation
} = userApi
