import { Drawer, DrawerOverlay } from "@chakra-ui/react"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { clearDrawer } from "../../../features/drawer/drawerSlice"
import { clearDocumentState } from "../../../features/document/documentSlice"
import { RootState } from "../../../app/store"
import AddHomeDrawer from "../AddHome"
import ViewHomeDrawer from "../ViewHome"
import CreateDocumentDrawer from "../CreateDocument"
import FolderDetailsDrawer from "../FolderDetails"
import AddFolderDrawer from "../AddFolder"
import AccountListDrawer from "../AccountList"
import CreateItemDrawer from "../CreateItem"
import ContactList from '../ContactList'
import CreateHouseholdItemDrawer from '../CreateHouseholdItem'
import ProfileDrawer from "../Profile"
import DeleteHomesItemsDrawer from '../DeleteHomesItems'
import ReceiptList from "../ReceiptList"
import PartnerCatalogDrawer from '../PartnerCatalog'
import PropertyRecordsDrawer from '../PropertyRecords'
import MortgageInfoDrawer from '../MortgageInfo'
import AddPropertyRecordsDrawer from '../AddPropertyRecords'
import HomeAssistantDrawer from '../HomeAssistant'
import TileFormDrawer from '../TileForm'

const GlobalDrawer = () => {
  const dispatch = useAppDispatch()

  const closeDrawer = () => {
    dispatch(clearDrawer())
    dispatch(clearDocumentState())
    return
  }

  const { isOpen, content, location, size, data } = useAppSelector(
    (state: RootState) => state.drawer
  )

  const renderContent: any = {
    Default: () => <></>,
    AddHome: () => <AddHomeDrawer />,
    ViewHome: () => <ViewHomeDrawer handleClose={closeDrawer} />,
    CreateDocument: () => <CreateDocumentDrawer {...data}  />,
    FolderDetails: () => <FolderDetailsDrawer {...data} />,
    AddFolder: () => <AddFolderDrawer icon={data} />,
    AccountList: () => <AccountListDrawer handleClose={closeDrawer}/>,
    CreateHouseholdItem: () => <CreateHouseholdItemDrawer sentForm={data}/>,
    CreateItem: () => <CreateItemDrawer {...data}  />,
    ContactList: () => <ContactList/>,
    Profile: () => <ProfileDrawer handleClose={closeDrawer} defaultTab={data.defaultTab} />,
    DeleteHomesItems: () => <DeleteHomesItemsDrawer handleClose={closeDrawer} deleteHomes={data.deleteHomes}/>,
    ReceiptList: () => <ReceiptList homes={data.homes} onLaunchpad={data.onLaunchpad} handleClose={closeDrawer} />,
    PartnerCatalog: () => <PartnerCatalogDrawer onClose={closeDrawer} existingTiles={data} />,
    PropertyRecords: () => <PropertyRecordsDrawer handleClose={closeDrawer} />,
    MortgageInfo: () => <MortgageInfoDrawer handleClose={closeDrawer} />,
    AddPropertyRecords: () => <AddPropertyRecordsDrawer homeData={data} handleClose={closeDrawer} />,
    HomeAssistant: () => <HomeAssistantDrawer handleClose={closeDrawer} />,
    TileForm: () => <TileFormDrawer handleClose={closeDrawer} formData={data} />
  }

  return (
    <Drawer
      onClose={closeDrawer}
      isOpen={isOpen}
      placement={location}
      size={size ? size : "md"}
    >
      <DrawerOverlay />
      {renderContent?.[content]
        ? renderContent[content]()
        : renderContent.Default()}
    </Drawer>
  )
}

export default GlobalDrawer
