import { useState, useEffect } from "react"
import { ReceiptCardI, PaymentMethodI } from "@homefile/components/dist/interfaces"
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { RootState } from "../app/store";
import {ResponseType} from '../interfaces/DataInterfaces';
import { useGetUserCardsQuery,
    useUpdateUserMutation,
    useCreateUnAuthUserSubscriptionMutation
} from "../features/api";
import { setUser } from "../features/auth/authSlice";
import { setToken, clearAuth } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useSubscriptionData } from "./useSubscriptionData";
import {useCustomToast} from '@homefile/components'


interface PaymentInfoI {
    authenticated: boolean
    authState: any
}

export const usePaymentInfo = ({authenticated, authState}: PaymentInfoI) => {
    const user = useAppSelector((state: RootState) => state.auth.user);
    const stateAuthenticated = useAppSelector((state: RootState) => state.auth.isAuthenticated);
    const dispatch = useAppDispatch()
    const [receipts, updateReceipts] = useState<ReceiptCardI[]>([])
    const [userCard, updateCard] = useState({} as PaymentMethodI)
    const [skip, updateSkip] = useState(true)
    const {data} = useGetUserCardsQuery(null, {skip})
    const [updateUser] = useUpdateUserMutation()
    const [isLoading, updateLoading] = useState(false)
    const [cardLoading, updateCardLoading] = useState(false)
    const [openModal, updateOpenModal] = useState(false)
    const [modalHeader, updateModalHeader] = useState("Remove Credit Card")
    const [modalBody, updateModalBody] = useState("Are you sure you would like to remove this payment method?")
    const [actionText, updateActionText] = useState("")
    const [cancelText, updateCancelText] = useState("")
    const toast = useCustomToast()
    const navigate = useNavigate()
    const [addUnAuthSubscription] = useCreateUnAuthUserSubscriptionMutation()
    const {plans} = useSubscriptionData(0)
    const [cardSuccess, updateCardSuccess] = useState(false)
    const [targetPlanId, updateTargetPlanId] = useState("")
    const [needsRedirect, updateRedirect] = useState(false)

    useEffect(() => {
        if (data) {
            const cards: any[] = data as any[]
            if (cards.length > 0) {
                updateCard({
                    brand: cards[0].brand,
                    number: cards[0].lastCardNumbers,
                    isPaymentMethodValid: true,
                })
            } else {
                updateCard({} as PaymentMethodI)
            }
        }
    }, [data])

    useEffect(() => {
        if (stateAuthenticated && needsRedirect) {
            navigate("/dashboard");
        }
    }, [needsRedirect, stateAuthenticated])

    const handleUnAuthSubscription = async ({cardId, token, planId}: {cardId: string, token: string, planId?: string}) => {
        updateLoading(true)
        const passPlan: string = planId || targetPlanId
        const newSubscription: ResponseType = await addUnAuthSubscription({
            token,
            body: {
                cardId,
                planId: passPlan
            }
        })

        if (newSubscription.data) {
            updateCardLoading(false)
            updateLoading(false)
            toast({
                title: 'Information Updated!',
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
            if (authState !== null) {
                dispatch(clearAuth())
                navigate("/login")
            }
        } else {
            updateCardLoading(false)
            updateLoading(false)
            updateModalHeader("Something went wrong.")
            updateModalBody("Please try again later.")
            updateActionText("")
            updateCancelText("Close")
            updateOpenModal(true)
            console.log('subscription fail res:', newSubscription)
        }
    }

    useEffect(() => {
        if (plans.length > 0) {
            const targetPlan = plans.filter((plan: any) => plan.name === "Home Yearly plan")[0]
            updateTargetPlanId(targetPlan._id)
        }
    }, [plans])


    useEffect(() => {
        if (authenticated && authState !== null) {
            if (skip) {
                updateSkip(false)
            }
        } else if (authenticated) {
            updateSkip(false)
        }
    }, [authenticated, authState])




    const handleDrawerSubmit = async (form: any) => {
            updateLoading(true)
            const updatedUser = {
                ...user, 
                email: form.userFields.email,
                firstName: form.userFields.firstName,
                lastName: form.userFields.lastName
            }

            const userRes: ResponseType = await updateUser(updatedUser)
            if (userRes.data) {
                dispatch(setUser(userRes.data));
                toast({
                    title: 'Information Updated!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: "top-right"
                })
                updateLoading(false)
            
            } else {
                updateLoading(false)
                console.log("user update fail", userRes)
                updateModalHeader("Something went wrong.")
                updateModalBody("Please try again later.")
                updateActionText("")
                updateCancelText("Close")
                updateOpenModal(true)
            }
    }

    const handleCloseModal = () => {
        updateOpenModal(false)
    }

    const handleCardSuccess = () => {
        updateCardSuccess(true)
    }

    return {
        handleCloseModal,
        handleDrawerSubmit,
        handleUnAuthSubscription,
        receipts,
        userCard,
        isLoading,
        openModal,
        modalHeader,
        modalBody,
        actionText,
        cancelText,
        user,
        cardLoading,
        cardSuccess,
        handleCardSuccess
    }

}