import { useState, useEffect } from "react";
import {
  DrawerBody,
  DrawerHeader,
  DrawerContent,
  DrawerFooter,
  Box,
  Flex,
  Image
} from "@chakra-ui/react";
import {ResponseType} from '../../../interfaces/DataInterfaces';
import {
    useGetServiceRequestsQuery, 
    useCreateServiceRequestMutation, 
    useCreateServiceRequestNoteMutation, 
    useDeleteServiceRequestMutation
} from '../../../features/api'
import { Calendar } from "../../../assets/images";
import {PanelHeader, FooterDrawer, FooterButtons, DynamicForm, dynamicFormProxy, useCustomToast} from '@homefile/components';

interface TileFormDrawerI {
    handleClose: () => void
    formData: any
}

const TileFormDrawer = ({handleClose, formData}: TileFormDrawerI) => {
    const [submitTileForm] = useCreateServiceRequestMutation()
    const toast = useCustomToast()
    const [form, updateForm] = useState([])
    const [tileId, updateTileId] = useState("")
    const [headerIcon, updateHeaderIcon] = useState("")
    const [mainLogo, updateMainLogo] = useState("")

    useEffect(() => {
        if (formData.form) {
            updateForm(formData.form.children)
            updateMainLogo(formData.logo)
            updateTileId(formData.tile)
        }
    }, [formData])

    const recursiveObj = (obj: any, flatten: any[] = []) => {
        // base case
        if (obj.children !== undefined && obj.children.length > 0) {
            const children = obj.children
            delete obj.children
            if (obj.type !== "checkbox") {
                const passObj: any = {
                    id: obj.id,
                    description: obj.description ? obj.description : "", 
                    value: obj.value, 
                    type: obj.type,
                    name: obj.name ? obj.name : ""
                }
                flatten = [...flatten, passObj]
            }
            children.forEach((child: any) => {
                const childObj = recursiveObj(child, flatten)
                flatten = childObj
            })
        } else {
            if (obj.type !== "checkbox") {
                const passObj: any = {
                    id: obj.id,
                    description: obj.description ? obj.description : "", 
                    value: obj.value, 
                    type: obj.type,
                    name: obj.name ? obj.name : ""
                }
                return [...flatten, passObj]
            }
        }
        return flatten
    }

    const handleSubmit = async () => {
        const {fields} = dynamicFormProxy
        const nestedForm: any = {...formData.form, children: fields}
        const sendForm = recursiveObj(nestedForm)


        const passData = {
            tile: tileId,
            form: sendForm
        }
        const submitForm: ResponseType = await submitTileForm(passData)
        
        if (submitForm.data) {
            toast({
                title: 'Success',
                description: `${formData.form.name} submitted`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
            handleClose()
        } else {
            console.log('submit form error', submitForm)
            toast({
                title: 'Failure',
                description: `Please try again later`,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: "top-right"
            })
        }
    }

    const ButtonsFooter = {
        button1: {
            buttonStyle: 'primaryFooter',
            label: ('Submit'),
            onClick: () => {handleSubmit()},
        },
        button2: {
            buttonStyle: 'secondaryFooter',
            label: ('Cancel'),
            onClick: handleClose,
        }
      }

    return (
        <DrawerContent p="0">
            <DrawerHeader p="0">
                <PanelHeader
                    handleCloseButton={handleClose}
                    icon={Calendar}
                    title={formData.form.name}
                />
            </DrawerHeader>

            <DrawerBody p="0" background={"#e9edef"}>
                <Flex borderTop={"4px solid #f4f7f8"} p={".5rem"} background={"#ffffff"}>
                    <Image
                        src={mainLogo}
                        alt={"Partner Logo"}
                    />
                </Flex>

                <Box overflow="hidden" >
                    {
                        form.length > 0 && (
                            <DynamicForm 
                                form={form}
                                showTitle={formData.form.description !== "" ? true : false}
                                title={formData.form.description}
                            />
                        )
                    }
                </Box>

            </DrawerBody>

            <DrawerFooter zIndex={"999"}>
                <FooterDrawer
                    children={
                        <FooterButtons
                        button1={ButtonsFooter.button1}
                        button2={ButtonsFooter.button2}
                        />
                    }
                    isOpen={true}
                    />

            </DrawerFooter>
        </DrawerContent>
    )
}

export default TileFormDrawer