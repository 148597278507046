import { homefileApi } from './emptySplitApi'

const serviceApi = homefileApi.injectEndpoints({
    endpoints: (builder) => ({
        getServiceRequests: builder.query({
            query: (data: any) => ({
                url: "/api/request-service",
                params: {limit: `10`, page: `1`},
                headers: {
                Pagination: '{"page": 1, "limit": 10}',
                },
            }),
            providesTags: ["Service"],
        }),
        createServiceRequest: builder.mutation({
            query: (data: any) => ({
                url: "/api/request-service",
                method: "POST",
                body: data,
                headers: {
                "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["Service"],
        }),
        approveServiceRequest: builder.mutation({
            query: (id: string) => ({
                url: `/api/request-service/approve-service-request/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["Service"],
        }),
        createServiceRequestNote: builder.mutation({
            query: (data: any) => ({
                url: `/api/request-service/note/${data.serviceId}`,
                method: "POST",
                body: data,
                headers: {
                "Content-Type": "application/json",
                },
            }),
            invalidatesTags: ["Service"],
        }),
        deleteServiceRequest: builder.mutation({
            query: (id: string) => ({
                url: `/api/request-service/${id}`,
                method: "DELETE",
            }),
            invalidatesTags: ["Service"],
        }),
    })
})

export const {
    useGetServiceRequestsQuery,
    useCreateServiceRequestMutation,
    useCreateServiceRequestNoteMutation,
    useDeleteServiceRequestMutation,
    useApproveServiceRequestMutation
} = serviceApi