import { useGetDefaultConfigQuery } from "../features/api";
import { useEffect, useState } from "react";

export const useGetDefaultConfig = () => {
    const {data} = useGetDefaultConfigQuery(null)
    const defaultInit = {
        config: {
            contactTypeId: "",
            constructionTypeId: "",
            progressReportSubTypeId: ""
        }
    }
    const [defaultConfig, updateConfig] = useState<any>(defaultInit as any)
    useEffect(() => {
        if (data) {
            updateConfig(data)
        }
    }, [data])

    return {
        defaultConfig
    }
}